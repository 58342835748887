import React from "react";
import { Link } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
//import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";

import firstCaseMockup from "../images/runnermock1.png";
import firstCaseLogo from "../images/runner.svg";
import feraName from "../images/mafer.svg";
import need1 from "../images/interactions.png";
//import need2 from "../images/need2.png";
//import needswritten from "../images/placeneeds.jpg";
import journey from "../images/runnerproto.png";
import features from "../images/schematicrunner.png";
import wire1 from "../images/code1.png";
import wire2 from "../images/code3.png";
//import figma1 from "../images/figma1.png";
import homecase1 from "../images/runnerfinal.png";
/*
import resultscase1 from "../images/resultscase1.png";
import descriptioncase1 from "../images/descriptioncase1.png";
import faqscase1 from "../images/faqscase1.png";
import confirmcase1 from "../images/confirmcase1.png";
*/

function PlanneRunnerPage() {
    return (
        <Layout>
            <SEO
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                title=""
            />

            <section>
            <Link to="/">
                <div className="relative h-auto w-auto ...">
                    <div>
                        <img
                            alt="Case One"
                            className="md:w-64 object-cover "
                            src={feraName}
                        />
                        {/*   <h2 className=" inline-block p-0.5 px-4 mb-4 leading-tight text-2xl font-semibold tracking-wide text-gray-800 bg-green-200">
          Fernanda Ramírez
        </h2> */}
                    </div>
                    <div className="-m-2">
                        <h2 className="inline-block p-0 px-4 mt-4 mb-4 md:text-1xl font-light text-gray-800 text-sm">
                            Product Designer
        </h2>
                    </div>

                </div>
                </Link>
            </section>

            <section className="relative flex items-center justify-center">
                <div className="md:flex-shrink-0">

                    <img
                        alt="Case One"
                        className="h-16 md:w-40 w-24"
                        src={firstCaseLogo}
                    />
                </div>

            </section>

            <section className="relative flex items-center justify-center">

                <p className="block mt-1 text-lg leading-tight text-gray-600 font-thin">micro:bit Powered Toy & Game</p>
            </section>

            <section>
                <div className="md:flex-shrink-0 md:p-12 p-8 pt-16 md:pt-20">


                    <img
                        alt="Case One"
                        className="h-full w-full object-cover "
                        src={firstCaseMockup}
                    />
                </div>

                <div className="relative flex items-center justify-center grid grid-cols-4 gap-2 ">
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">Year</div>
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center ">Duration</div>
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">Semester</div>
                    <div className="uppercase tracking-wide text-base text-indigo-500 font-semibold text-center">Group</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center">2020</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center ">4 months</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center">1st (Master)</div>
                    <div className=" tracking-wide text-base text-gray-900 font-light text-center">Yes</div>
                </div>

                <div className="relative flex items-center justify-center grid grid-cols-1 pt-20 ">
                    <div className="tracking-wide text-2xl text-black font-extrabold text-center ">The context</div>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2 ">Finding a way in which kids can play with games that coexist in both the physical and the digital world is not as easy as it may look like. Nowadays, since their early age, kids are surrounded by games that only exploit their capabilities from a digital perspective, leaving behind the chance for them to develop certain motor skills that come from playing with real tangible toys. With that in mind and considering the endless possibilities that combining regular toys with sensors can bring, plane runner is a game that unleashes creativity to take fun, interaction and engagement between both worlds to a higher level. 
</p>
                </div>

                <div className="relative flex items-center justify-center grid grid-cols-1 pt-12 ">
                    <div className="tracking-wide text-2xl text-black font-extrabold text-center pb-6">The process</div>
                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-4 md:w-2/5 w-4/5 bg-green-100 mt-8">1. Defining the game</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">We first started thinking about a way in which a regular toy could be blended with a digital game that has mechanics that are widely known by most kids. </p>
                    <p className="block text-left mt-0 text-base  text-gray-900 font-light py-0 md:px-20 px-2">We landed our thoughts into an endless-runner kind of game. Which in essence is a game that moves continuously forward through a hazardous course.  </p>
                    <p className="block text-left mt-0 text-base  text-gray-900 font-light py-6 md:px-20 px-2">In our case, the player controls a virtual plane with a physical toy plane. The goal of the game is to go as far as possible without crashing. The game is set to work under a limited amount of time that can be increased by collecting packages.</p>

                  

                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-4 md:w-3/5 w-auto bg-green-100 mb-8">2. Defining the interactions</p>

                    <p className="block text-left mt-0 text-base  text-gray-900 font-light py-6 md:px-20 px-2">After defining the general dynamics and mechanics of the game, we divided the interactions that would take place in our project. In general terms, we encompassed them in two categories: physical and virtual interactions. Physical being the ones the user performs with the plane and virtual the ones performed by the virtual plane in-game.</p>

                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-6 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover "
                                src={need1}
                            />
                        </div>
                        
                    </div>

                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-16 grid-cols-1 md:p-8 p-2 ">

                        <div className=" bg-white rounded-lg shadow-md overflow-hidden ">
                            <div className="md:flex">

                  

                            </div>
                        </div>



                    </div>

                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-6 md:w-3/5 w-auto bg-green-100 mb-8 mt-8">3. Designing the Toy</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">We designed a toy based on the dimensions of the hand of a kid. We made a 3D model to plan the placement of the electronic components. And we set up the parts to laser-cut it in order to build it.</p>

                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={journey}
                            />

                        </div>



                    </div>

                    <p className="block text-left mt-1 text-black text-gray-900 font-semibold md:px-20 px-6 md:w-3/5 w-auto bg-green-100 mb-8 mt-8">4. Jumping into the Development </p>

                    
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">After designing the toy, we started working on the game itself. We decided to develop it using a micro:bit and Unity as the game engine.</p>

                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">Our project takes advantage of most of the sensors that come within the micro:bit (accelerometer, LEDs, buttons). For that reason, we only needed to add a button and a led to proceed with the development as can be seen in the scheme. In general terms, the connection between the micro:bit and the game engine is made using the UART over BLE.</p>

                    <div className="relative flex items-center justify-center grid md:grid-cols-1 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={features}
                            />

                        </div>



                    </div>

                    <div className=" tracking-wide text-base text-gray-900 font-bold mt-8 md:px-20 px-2">BLE Communication</div>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">Microbit exposes data to specific characteristics according to the Lancaster BLE Protocol. In case of accelerometer, the bluetoothAccelerometerService available is instantiated in the microbit code to expose it.</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-0 md:px-20 px-2">Our Unity Script(c#) instantiates the BLE Library, searches for the microbit name and connects to the Device. After establishing connection, our script then subscribes to each characteristic to
receive its updates in byte[].</p>
                    <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">We then process those bytes[] into useful information that can help us send the instructions from the plane to the game </p>

                    <div className="relative flex items-center justify-center grid md:grid-cols-2 gap-2 grid-cols-1 ">
                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={wire1}
                            />


                        </div>

                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded "
                                src={wire2}
                            />
                        </div>

                    </div>

                    
                        <div className="tracking-wide text-2xl text-black font-extrabold text-center md:pb-6 md:pt-0 pt-8">The result</div>
                        <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">We finally came up with a fully functional game that is set up using low poly assets, an object pooler and an object spawner. </p>


                        <div className="md:flex-shrink-0 md:p-12 p-2 pt-8 md:pt-8">
                            <img
                                alt="Case One"
                                className="h-full w-full object-cover rounded py-8"
                                src={homecase1}
                            />

                        </div>
                        <p className="block text-left mt-1 text-base  text-gray-900 font-light py-6 md:px-20 px-2">And yes! The game can be controlled with the plane we designed.</p>
                        

                        <p className="block text-left mt-1 text-sm italic  text-gray-900 font-light py-6 md:px-20 px-2">* My role: Unity Game Design and Development & micro:bit communication * </p>


                        <div className="tracking-wide text-xl text-gray-700 font-bold text-center pb-6">Thank you for reading!</div>

                </div>

            </section>

        </Layout>
    );
}

export default PlanneRunnerPage;
